import axios from "axios";

import { Store } from "react-notifications-component";
import urls from "../urls";
// axios.defaults.adapter = require('axios/lib/adapters/http');
axios.defaults.headers.common["Content-Type"] = "application/json";

const url = urls.baseUrl;



function checkResponse(response){
  if (response.status !== 200) {
    const error = new Error(response.message);
    throw error;
  } else {
    const newResponse = {
      ...response.data,
    };
    return response.data;
  }
}

export function displayError(message) {
  // const lang = Cookies.get("i18next");
  Store.addNotification({
    title: "error occured",
    message: message || "Try Again",
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      showIcon: true,
      onScreen: true,
    },
  });
}

export async function postToApi(
  path,
  body,
  config
) {
  const response = await axios
    .create({
      baseURL: url,
    })
    .post(path, body, config);
  return checkResponse(response);
}

export async function deleteToApi(
  path,
  config
) {
  const response = await axios.delete(path, config);
  return checkResponse(response);
}




export default axios.create({ baseURL: url });
