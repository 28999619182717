const routerLinks = {
  ////////////////////////////
  homePage: '/',
  ////////////////////////////

  ////////////////////////////
  ////////////////////////////////
  contactPage: '/contact',
  PolicyPage: '/Policy',
  ConditionPage: '/Condition',
  returnPage: '/return',
  daftarPage: '/daftar',
  payPage: '/pay',
  HelpFatorah: '/Help',
  ////////////////////////////
  ////////////////////////////////
  notFound: '/not-found'
};

export default routerLinks;
