import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
// import UesrContext from '../../contexts/user-context/UserProvider';
import { Store } from "react-notifications-component";

import AntdTextField from "../../../common/antd-form-components/AntdTextField";
import { postToApi } from "../../../apis/apis";
import AntdTextarea from "../../../common/antd-form-components/AntdTextarea";
import "./ContactForm.css";
const schema = Yup.object().shape({});

const ContactForm = () => {
  const { i18n } = useTranslation();
  //   const { setCurrentUser, setUserToState } = useContext(UesrContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {},
  });
  const onSubmit = async (data) => {
    try {
      const res = await postToApi(
        "SiruSolutionsRequests/add-sirusolutionsrequest",
        data
      );
      console.log("res: ", res);
      if (!res) {
        Store.addNotification({
          title: "error",
          message: res?.data?.message || "حدث خطأ",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else if (res) {
        console.log("🚀 ~ onSubmit ~ res:", res)

        // if (data.remember) setCurrentUser({ ...res });
        // else setUserToState({ ...res });
     
        Store.addNotification({
          title: "تم",
          message: "تم ارسال رسالتك سيتم التواصل معك ف اسرع وقت",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="login-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <AntdTextField
          name="name"
          type="text"
          placeholder={"الاسم*"}
          control={control}
        />

        <AntdTextField
          name="phone"
          type="text"
          placeholder={"رقم الهاتف*"}
          control={control}
        />

        <AntdTextField
          name="email"
          type="text"
          placeholder={"البريد الالكتروني..."}
          control={control}
        />
        <AntdTextarea
          name="message"
          type="text"
          placeholder={"رسالتك*"}
          control={control}
        />

        <Button
          className="submit-btn"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1.3rem 1.5rem",
            backgroundColor: "#C8FC89",
            color: "#1D463E",
            fontWeight: "bold",
          }}
          htmlType="submit"
          type="primary"
          loading={isSubmitting}
        >
          تواصلوا معي
        </Button>
      </div>
    </Form>
  );
};

export default ContactForm;
