import React from "react";
import { useController } from "react-hook-form";
import { AutoComplete, Form, Input } from "antd";

const AntdTextField = ({
  control,
  name,
  type,
  label,
  placeholder,
  prefix,
  validateStatus,
  defaultValue,
  errorMsg,
  disabled,
  autoComplete,
  accept,
  step,
  onChange
}) => {
  const {
    // field: { ...inputProps }
    field,
  } = useController({
    name,
    control,
  });

  return (
    <Form.Item
      label={label}
      help={errorMsg}
      validateStatus={validateStatus}
      colon={false}
    >
      <Input
        {...field}
        placeholder={placeholder}
        prefix={prefix}
        type={type}
        size="large"
        readOnly={disabled}
        defaultValue={defaultValue}
        // autoComplete="off"
        autoComplete={autoComplete}
        accept={accept}
        step={step}
        // onChange={onChange}

        // value={defaultValue ? defaultValue : field.value}
      />
    </Form.Item>
  );
};

export default AntdTextField;
