import React, { useEffect } from 'react';

// import HeroSection from '../../components/home-page-components/HeroSection';

import DocTitleScrollTop from '../../utils/DocTitleScrollTop';

import './HomePage.scss';

import MianAppBar from '../../components/main-app-bar/MianAppBar';
import HeroSection from './Home-compoents/HeroSection';
import HeroSlider from './Home-compoents/HeroSlider';
import OurService from './Home-compoents/OurService';
import MainAppFooter from '../../components/main-app-footer/MainAppFooter';
import ContactUs from './Home-compoents/ContactUs';
import OurPropose from './Home-compoents/OurPropose';
// import App from '../../AppCCC';

const HomePage = () => {
  DocTitleScrollTop();

  useEffect(() => {
  }, []);
  return (
    <div className="home-page">

      <div className="page-wrapper">
        <MianAppBar />
        {/* <App/> */}
        <HeroSection />
        <div style={{ background: "#2E2E39 !important" }}>

          <HeroSlider />
          <OurPropose />
        </div>
        <OurService />
        <ContactUs />
        <MainAppFooter />
      </div>
    </div>
  );
};

export default HomePage;
