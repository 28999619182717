import { useEffect } from "react";

const DocTitleScrollTop = docTitle => {
	return useEffect(() => {
		window.scrollTo(0, 0);
		document.title = `${docTitle ? `${docTitle} |` : ""} ${"Sirumapp website"}`;

		return () => {
			document.title = "Sirumapp website";
		};
	}, [docTitle]);
};

export default DocTitleScrollTop;
