import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/app/App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "./common/loading/Loading";
import { ReactNotifications } from "react-notifications-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import 'react-whatsapp-widget/dist/index.css';
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";

// Alternate way to use classes without prefix like `animated fadeIn`
import 'animate.css/animate.compat.css'


import "aos/dist/aos.css";
import "./scss/index.scss";
import "./scss/index.css";
import "./scss/zeinet-rtl.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReactNotifications/>
    <Suspense fallback={<Loading />}>
      <HelmetProvider>
        <Router>
          <App />
        </Router>
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
