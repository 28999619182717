import React, { useRef } from "react";

import oneMobile from "../../../assets/imgs/bg/Mask.png";
import one from "../../../assets/imgs/bg/Mask.png";

import "./OurService.scss";
import { useTranslation } from "react-i18next";
const OurService = () => {
  const { t } = useTranslation();
  const windowWidth = useRef(window.innerWidth);

  return (
    <section
      className="plans-one"
      id="services"
      style={{ background: "linear-gradient(#fff, #e6e3d6)" }}
    >
      <div className="container">
        <div className="section-title text-center">
          <h2 className="section-title__title Noto-extra-Bold h2-title">
            خدماتنا
          </h2>
          <p className="about-one__text-2 Noto-Regular">
            ملتزمون بتقديم التميز من خلال الإبداع والتعاون والتكنولوجيا الحديثة.
            من خلال التركيز على النزاهة والاحترافية ورضا العملاء
          </p>
        </div>
      </div>
      <div className="main-div">
        <div className="service-container container">
          <div className="row">
            <div className="dashed-absolute-left"></div>
            <div className="dashed-absolute-right"></div>
            <div className="col-lg-4 col-md-6 col-sm-12 one-service">
              <div className="content">
                <img
                  src="assets/images/icon/design.png"
                  className="img-width"
                  alt=""
                />
                <h3 className="Noto-Regular title">
                  {" "}
                  تصميم المواقع الإلكترونية
                </h3>
                <p className="Noto-Regular desc">
                  نحن نستخدم أحدث التقنيات لضمان تقدمك دائمًا على المنافسين.
                  التزامنا بالابتكار يضمن أن مشاريعك مدعومة بأدوات الصناعة
                  الأكثر تقدمًا.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 one-service">
              <div className="content">
                <img
                  src="assets/images/icon/Vector2.png"
                  className="img-width"
                  alt=""
                />
                <h3 className="Noto-Regular title">تصميم متاجر إحترافية</h3>
                <p className="Noto-Regular desc desc">
                  نحن نستخدم أحدث التقنيات لضمان تقدمك دائمًا على المنافسين.
                  التزامنا بالابتكار يضمن أن مشاريعك مدعومة بأدوات الصناعة
                  الأكثر تقدمًا.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 one-service">
              <div className="content">
                <img
                  src="assets/images/icon/vector3.png"
                  className="img-width"
                  alt=""
                />
                <h3 className="Noto-Regular title">
                  برمجة خاصة لمواقع و تطبيقات الويب
                </h3>
                <p className="Noto-Regular desc">
                  نحن نستخدم أحدث التقنيات لضمان تقدمك دائمًا على المنافسين.
                  التزامنا بالابتكار يضمن أن مشاريعك مدعومة بأدوات الصناعة
                  الأكثر تقدمًا.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 one-service">
              <div className="content">
                <img
                  src="assets/images/icon/vector4.png"
                  className="img-width"
                  alt=""
                />
                <h3 className="Noto-Regular title">تصميم و برمجة التطبيقات</h3>
                <p className="Noto-Regular desc">
                  نحن نستخدم أحدث التقنيات لضمان تقدمك دائمًا على المنافسين.
                  التزامنا بالابتكار يضمن أن مشاريعك مدعومة بأدوات الصناعة
                  الأكثر تقدمًا.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 one-service">
              <div className="content">
                <img
                  src="assets/images/icon/vector5.png"
                  className="img-width"
                  alt=""
                />
                <h3 className="Noto-Regular title">
                  تهيئة الموقع في محركات البحث
                </h3>
                <p className="Noto-Regular desc">
                  نحن نستخدم أحدث التقنيات لضمان تقدمك دائمًا على المنافسين.
                  التزامنا بالابتكار يضمن أن مشاريعك مدعومة بأدوات الصناعة
                  الأكثر تقدمًا.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 footer"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurService;
