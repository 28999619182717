import React, { useEffect, useRef } from "react";
import "./HeroSlider.scss";
import star from "../../../assets/imgs/icons/octicon_north-star-16.png";
const HeroSlider = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleAnimation = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const elements = entry.target.querySelectorAll(".anim");

          elements.forEach((element, index) => {
            element.style.animationDelay = `${index * 0.3}s`;
            element.classList.add("animate");
          });
        } else {
          const elements = entry.target.querySelectorAll(".anim");

          elements.forEach((element) => {
            element.style.animationDelay = "";
            element.classList.remove("animate");
          });
        }
      });
    };

    const observer = new IntersectionObserver(handleAnimation, {
      threshold: 0.2, // Adjust this threshold as needed
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <>
      <section id="our-message" style={{paddingBottom:0}}>

        <div className="scrolling-text-container">
          <div className="scrolling-text-inner" role="marquee">
            <div className="scrolling-text">
              <div className="scrolling-text-item Noto-Regular contact-text">
                <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              > سيروماب </div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              >  تطبيقات</div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              >  مواقع</div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              >  برمجة</div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              > تصميم</div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              >  سيروماب
              </div>
            </div>
            <div className="scrolling-text">
              <div className="scrolling-text-item Noto-Regular contact-text">
                <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              > سيروماب </div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              >  تطبيقات</div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              >  مواقع</div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              >  برمجة</div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              > تصميم</div>
              <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
              >  <img src={star} alt="" />
              </div>

              <div className="scrolling-text-item Noto-Regular contact-text contact-title"
              >  سيروماب
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ background: "#2E2E39 !important" }}>
        <section
          style={{ paddingBottom: "40px", backgroundColor: "#2E2E39 !important" }}
          className="slider-sec"
        >
          {/* <div
            className="scrolling-text-container"
            style={{ direction: "ltr !important" }}
          >
            <div className="scrolling-text-inner-slider" role="marquee">
              <div className="scrolling">
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> سيروماب</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> تطبيقات</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> مواقع</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> برمجة</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> تصميم</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> سيروماب</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> تطبيقات</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> مواقع</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> برمجة</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> تصميم</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> سيروماب</div>
              </div>
              <div className="scrolling">
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> سيروماب</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> تطبيقات</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> مواقع</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> برمجة</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> تصميم</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> سيروماب</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> تطبيقات</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> مواقع</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> برمجة</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> تصميم</div>
                <div className="scrolling-text-item Noto-Regular">
                  <img src={star} alt="" />
                </div>
                <div className="scrolling-text-item Noto-Regular"> سيروماب</div>
              </div>
            </div>
          </div> */}
          <div className="container second-div ">
            <div className="move-section-head">
              <div className="point-div">
                <div className="point"></div>
                <div className="point2"></div>
                <div className="point3"></div>
                <div className="point4"></div>
                <div className="point5"></div>
                <div className="point6"></div>
                <div className="point7"></div>
                <div className="point8"></div>
                <div className="point9"></div>
                <div className="point10"></div>
                <div className="point11"></div>
                <div className="point12"></div>
                <div className="point13"></div>
                <div className="point14"></div>
                <div className="point15"></div>
                <div className="point16"></div>
              </div>
              <div className=" move-section-body style3">
                <div className=" style2 video-div">
                  <div className="video-height style1">
                    <video
                      className="video-height-for-video"
                      src="assets/images/icon/pexels-rostislav.mp4"
                      style={{ borderRadius: "15px" }}
                      loop
                      autoPlay
                      muted
                    ></video>
                  </div>
                </div>

                <div className="div-text-video">
                  <div className="css-typing text-video" ref={sectionRef}>
                    <p className="anim animate">
                      1 &nbsp;&nbsp; def creativity(n):
                    </p>
                    <p className="anim animate">
                      2 &nbsp;&nbsp; """Calculate the factorial
                    </p>
                    <p className="anim animate">3 &nbsp;&nbsp; integer."""</p>
                    <p className="anim animate">4 &nbsp;&nbsp; if n == 10/10:</p>
                    <p className="anim animate">5 &nbsp;&nbsp; return 1</p>
                    <p className="anim animate">6 &nbsp;&nbsp; else:</p>
                    <p className="anim animate">
                      7 &nbsp;&nbsp; return n * factorial(n - 1)
                    </p>
                    <p className="anim animate">8 &nbsp;&nbsp; try:</p>
                    <p className="anim animate">
                      9 &nbsp;&nbsp; num = int(input("Enter a non-negative integer
                      to
                    </p>
                    <p className="anim animate">
                      10 &nbsp;&nbsp; calculate its factorial: "))
                    </p>
                    <p className="anim animate">11 &nbsp;&nbsp; if num 0:</p>
                    <p className="anim animate">
                      12 &nbsp;&nbsp; print("Factorial is not defined for negative
                    </p>
                    <p className="anim animate">13 &nbsp;&nbsp; numbers.")</p>
                    <p className="anim animate">14 &nbsp;&nbsp; else:</p>
                    <p className="anim animate">
                      15 &nbsp;&nbsp; print("Factorial of", num, "is",
                      factorial(num))
                    </p>
                    <p className="anim animate">
                      16 &nbsp;&nbsp; except ValueError:
                    </p>
                    <p className="anim animate">
                      17 &nbsp;&nbsp; print("Error: Please enter a valid
                      integer.")
                    </p>
                  </div>
                </div>
                {/* <div className="video-mob" style={{ backgroundColor: "rgba(2, 39, 39, 1)" }}>
                                <video className="video-height-for-video"
                                    src="assets/images/icon/pexels-rostislav-uzunov-8303104 (540p)_resized.mp4"
                                    style={{
                                        borderRadius: "15px", width: "100%",
                                        margin: "29px auto"
                                    }} loop autoPlay muted></video>
                            </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default HeroSlider;
