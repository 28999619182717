import React from 'react';
import { Route, Routes } from 'react-router-dom';


import NotFoundPage from '../../pages/not-found-page/NotFoundPage';

import routerLinks from './routerLinks';

// import { PageTransition } from '@steveeeie/react-page-transition';

import HomePage from '../../pages/home-page/HomePage';

const Routess = () => {
  return (
    <Routes>
      <Route exact="true" path={routerLinks.homePage} element={<HomePage />}>
        
      </Route>
      <Route path="*" element={NotFoundPage} />
    </Routes>
  );
};

export default Routess;
