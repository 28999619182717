import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import LanguageButton from '../../common/language-button/LanguageButton';
// import Logo from '../../assets/imgs/bg/header-logo.svg';
import mainAppBarLinks from './mainAppBarLinks';
import './MainAppBarLg.scss';
import routerLinks from '../app/routerLinks';
// import PakedageContext from '../../contexts/pakedage-context/PakedageProvider';
import logo from '../../assets/imgs/sirulogo.png';

const MainAppBarLg = ({ className }) => {
  const { t } = useTranslation();
  // const history = useHistory();
  // const { loadingPakedage } = useContext(PakedageContext)
  const windowWidth = useRef(window.innerWidth);

  const hanldeClick = (section) => () => {
    if (pathname === routerLinks.homePage && section !== '') {
      document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // history.push(routerLinks.HelpFatorah, {});
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === routerLinks.contactPage) {
    } else {
    }
  }, [pathname]);

  const renderNavLinks = () => {
    return (
      <ul>
        {mainAppBarLinks(t, pathname).map(({ id, name, link, section }) => (
          <li
            key={id}
          // className={`${section === loadingPakedage
          //     ? 'Active'
          //     : 'notActive'
          //   }`}
          >
            <NavLink to={link} exact onClick={hanldeClick(section)}>
              {name}
              <div className="active-img-wrap"></div>
            </NavLink>
          </li>
        ))}
        {/* <li>
        <a
          href={`${windowWidth.current > 1025 ? "https://web.whatsapp.com/send?phone=966550570058" : "https://api.whatsapp.com/send?phone=966550570058"}`}
          target="_blank"
          rel="noreferrer">
          تواصل معانا
          </a>
        </li> */}
      </ul>
    );
  };
  return (
    // <div className={className} id='main-app-bar'>
    //   <div className="nav-content-wrapper">
    //     <Link
    //       className={`main-app-bar-logo`}
    //       onClick={hanldeClick('hero-section')}
    //       to={routerLinks.homePage}>
    //       <div className="" style={{ display: "flex" }}>
    //         <img src={Logo} alt="app logo" className={` `} />
    //       </div>

    //     </Link>
    //     <div className="nav-lang-wrapper">
    //       {renderNavLinks()}
    //       <LanguageButton />
    //     </div>
    //   </div>
    // </div>
    <header className={`${className} main-header`}>
      <nav className="main-menu">
        <div className="">
          <div className="main-menu__wrapper clearfix">
            <div className="main-menu__left">
              <div className="main-menu__logo">
                <a><img className='main-menu-logo-dim' src={logo} alt=""
                /></a>
              </div>
            </div>
            <div className="main-menu__right">

              <div className="main-menu__right-bottom">

                <div className="main-menu__main-menu-box">
                  <a href="#" className="mobile-nav__toggler"><i className="fa fa-bars"></i></a>
                  <ul className="main-menu__list">

                    <li className="">
                      <a href="#home" className="Noto-Regular">الرئيسية</a>

                    </li>
                    <li >
                      <a className="Noto-Regular" href="#about">عن الشركة</a>

                    </li>

                    <li >
                      <a className="Noto-Regular" href="#services">خدماتنا</a>

                    </li>

                    {/* <li >
                      <a className="Noto-Regular" href="#contact">تواصل معنا</a>
                    </li> */}
                  </ul>
                </div>
                <div className="main-menu__main-menu-content-box" style={{ position: "absolute", right: " 87%" }}>
                  <div className="main-menu-three__btn-box">
                    <a href="#contact" className="thm-btn main-menu-three__btn" style={{ background: "#016457 !important", color: "#BCFC74 !important" }}>تواصل معنا</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainAppBarLg;
