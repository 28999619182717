import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./HeroSection.css";
import img1 from "../../../assets/imgs/hero-section/img1.png";
import img2 from "../../../assets/imgs/hero-section/img2.png";
import img3 from "../../../assets/imgs/hero-section/img3.png";
import img4 from "../../../assets/imgs/hero-section/Frame 132.png";
import camelPC from "../../../assets/imgs/bg/camel_web.jpeg";
import camelMob from "../../../assets/imgs/bg/camel-mobile.jpeg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // If you need to use ScrollTrigger

import SoftwarePC from "../../../assets/imgs/bg/pexels-fauxels-web.jpeg";
import SoftwareMob from "../../../assets/imgs/bg/pexels-fauxels-mobile.jpeg";

import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const [componies, setComponies] = useState([]);
  const { t } = useTranslation();
  const windowWidth = useRef(window.innerWidth);

  useEffect(() => {
    setComponies([
      {
        name: "اذهب إلى سيروا للحلول البرمجية",
        title: t("our_companies.sirusolutions_title"),
        desc: t("our_companies.sirusolutions_brief"),
        link: "google.com",
        icon: SoftwareMob,
        iconPC: SoftwarePC,
      },
      {
        title: t("our_companies.siruhigin_title"),
        desc: t("our_companies.siruhigin_brief"),
        name: "اذهب إلى سيروا للهجن",
        link: "google.com",
        icon: camelMob,
        iconPC: camelPC,
      },
    ]);
  }, [t]);
  const tl = useRef(gsap.timeline({ paused: true }));
  let linkAnimated = false;

  const handleMouseEnter = () => {
    const div1 = document.getElementById("div1");
    div1.style.display = "block";
    tl.current.restart();
  };

  useEffect(() => {
    const xTo = gsap.quickTo(".hidden-content", "--x", {
      duration: 0.4,
      ease: "power4.out",
    });
    const yTo = gsap.quickTo(".hidden-content", "--y", {
      duration: 0.4,
      ease: "power4.out",
    });

    tl.current.to(".hidden-content", {
      "--size": 250,
      duration: 0.75,
      ease: "back.out(1.7)",
    });

    const hoveringContent = document.querySelectorAll(".hover-div");

    hoveringContent.forEach((el) => {
      el.addEventListener("mouseenter", handleMouseEnter);
      el.addEventListener("mouseleave", () => {
        tl.current.reverse();
      });
    });

    const onFirstMove = (e) => {
      window.removeEventListener("mousemove", onFirstMove);
      gsap.set(".hover-div", {
        autoAlpha: 1,
        "--x": e.pageX,
        "--y": e.pageY,
        "--size": 5,
      });

      window.addEventListener("mousemove", (e) => {
        if (!linkAnimated) {
          yTo(e.pageY);
          xTo(e.pageX);
        }
      });
    };

    window.addEventListener("mousemove", onFirstMove);

    /***************************************
        Only for the preview image
    ***************************************/
    gsap.set(".hidden-content", {
      autoAlpha: 1,
      "--size": 4,
      "--x": window.innerWidth / 3,
      "--y": window.innerHeight / 2,
    });
    tl.current.progress(0.2);

    return () => {
      hoveringContent.forEach((el) => {
        el.removeEventListener("mouseenter", handleMouseEnter);
        el.removeEventListener("mouseleave", () => {
          tl.current.reverse();
        });
      });

      window.removeEventListener("mousemove", onFirstMove);
    };
  }, []);

  return (
    <div className="hero-container" id="home">
      {/* <div className="border-absolute-left"></div> */}
      {/* <div className="border-absolute-right"></div> */}
      <img
        src={img1}
        alt=""
        style={{ position: "absolute", top: "45%", left: "15%", zIndex: 20 }}
      />
      <img
        src={img2}
        alt=""
        style={{ position: "absolute", top: "60%", right: "25%" }}
      />
      <img
        src={img3}
        alt=""
        style={{ position: "absolute", top: "20%", left: "70%" }}
      />
      <div className="content" style={{ zIndex: 100 }}>
        <div className="container-fluid">
          <div className="row hero-row justify-content-center align-items-center min-vh-100">
            <div
              className="col-12 col-md-10 col-lg-8 d-flex justify-content-center align-items-center flex-column"
              style={{ textAlign: "center", backgroundColor: "transparent" }}
            >
              <h2
                className="Noto-Regular head-h1"
                style={{
                  padding: "50px 0",
                  fontSize: "35px",
                  textAlign: "center",
                }}
              >
                كن في الريادة التقنية مع
              </h2>
              <p
                className="main-head display-3 fw-bold text-center h1 Noto-extra-Bold  head-h2 hover-div typing-demo"
                style={{ color: "#2C4141" }}
                onMouseOver={handleMouseEnter}
              >
                حلول البرمجيات
              </p>
              <p
                className="secondary-head display-3 fw-bold text-center h1 Noto-extra-Bold head-h3 hover-div"
                style={{ color: "#68b116", marginBottom: "40px" }}
                onMouseOver={handleMouseEnter}
              >
                الأكثر تقدما
              </p>
              <p className="hero-paragraph">
                <span
                  className="Noto-Regular p-head "
                  style={{ fontSize: "18px", color: "#000" }}
                >
                  نحن نستخدم احدث التقنيات لضمان تقدمك دائما علي المنافسين
                  التزامنا بالابتكار يضمن أن مشاريعك مدعومة بأدوات الصناعة
                  الأكثر تقدما
                </span>
              </p>
            </div>
          </div>

          <div className="text-center" style={{ display: "none" }}>
            <a
              className="d-inline-flex justify-content-center align-items-center p-3"
              href="https://greensock.com/"
              target="_blank"
            >
              <i className="btn-icon bi bi-arrow-right-short fs-4"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="hidden-content" id="div1" style={{ display: "none" }}>
        <div className="container-fluid">
          <div className="row hero-row justify-content-center align-items-center min-vh-100">
            <div
              className="col-12 col-md-10 col-lg-8 d-flex justify-content-center align-items-center flex-column"
              style={{ textAlign: "center" }}
            >
              <h2
                className="Noto-Regular head-h1"
                style={{
                  padding: "50px 0",
                  fontSize: "35px",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                كن في الريادة التقنية مع
              </h2>
              <p
                className="main-head display-3 fw-bold text-center h1 Noto-extra-Bold head-h2  hover-div"
                style={{ color: "#C6FCA6", width: "100%" }}
              >
                حلول البرمجيات
              </p>
              <p
                className="secondary-head display-3 fw-bold text-center h1 Noto-extra-Bold head-h3 hover-div"
                style={{ color: "#C6FCA6", marginBottom: "40px" }}
              >
                الأكثر تقدما
              </p>
              <p className="hero-paragraph">
                <span
                  className="Noto-Regular p-head "
                  style={{ fontSize: "18px", color: "#000" }}
                >
                  نحن نستخدم احدث التقنيات لضمان تقدمك دائما علي المنافسين
                  التزامنا بالابتكار يضمن أن مشاريعك مدعومة بأدوات الصناعة
                  الأكثر تقدما
                </span>
              </p>
            </div>
          </div>

          <div className="text-center" style={{ display: "none" }}>
            <div className="d-inline-flex justify-content-center align-items-center p-3">
              <i className="btn-icon bi bi-arrow-right-short fs-4"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
