/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext } from 'react';
// import MomentUtils from '@date-io/moment';
// import { create } from 'jss';
// import rtl from 'jss-rtl';
import { useTranslation } from 'react-i18next';
// import { MainAppBarProvider } from "../../contexts/main-app-bar-context/MainAppBarProvider";
import Loading from '../../common/loading/Loading';
import Routes from './Routes';
import '../../i18n';
import AppLayout from './Layout';
import { useEffect } from 'react';
import axios from 'axios';
// import { getServicesApi } from '../../apis/services';
import MianAppBar from '../main-app-bar/MianAppBar';
// import Fade from 'react-reveal/Fade';

import AOS from 'aos';
import MainAppFooter from '../main-app-footer/MainAppFooter';
// import { ReactNotifications } from 'react-notifications-component';
// import PakedageContext from '../../contexts/pakedage-context/PakedageProvider';

axios.defaults.baseURL = 'https://websiteapi.fatoorah.sa/api';

function App() {
  // const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  useEffect(() => {
    AOS.init({
      duration: 1500
    });
  }, []);

  // const { setLoadingPakedage } = useContext(PakedageContext)


  const hanldeClick = (section) => () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {

    const handleScroll = event => {
      const currentRegion = [...document.querySelectorAll(".region:not([id=''])")]
        .find(e => e.getBoundingClientRect().top >= 0)

      if (currentRegion) {
        // window.location.hash = `#${currentRegion.id}`;
        // setLoadingPakedage(currentRegion.id)
        // console.log("currentRegion.id", currentRegion.id);
        // [...document.querySelectorAll(`a:not([href='#${currentRegion.id}'])`)]
        //   .forEach(a => a.classList.remove('red'))

        // document.querySelector(`a[href='#${currentRegion.id}']`)?.classList.add('red')
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => { }, [i18n.language]);

  return (
    // <Fade>
    <div className={`app app-${i18n.dir()}`} dir='i18n.dir()'>
      {/* <ThemeProvider theme={theme(i18n.dir())}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StylesProvider jss={jss}> */}
      <Suspense fallback={<Loading />}>
        <AppLayout>
          {/* <MianAppBar /> */}
          {/* <ReactNotifications /> */}
          <Routes />
          {/* <a id="backToTop" onClick={hanldeClick("hero-section")} className="showit"><i className="fa fa-angle-double-up"></i></a> */}
          {/* <MainAppFooter /> */}
        </AppLayout>
      </Suspense>
      {/* </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider> */}
    </div>
    // </Fade>
  );
}

export default App;
