import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import './OurPropose.scss'
import oneMobile from '../../../assets/imgs/bg/OurPurpose.jpeg'

import one from '../../../assets/imgs/bg/OurPurpose.jpeg'

const OurPropose = () => {
    const { t } = useTranslation()
    const windowWidth = useRef(window.innerWidth);


    return (
        <section className="plans-one" id="about">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className="section-title__title Noto-extra-Bold h2-title"
                        
                        >حلول رقمية للشركات الكبيرة والناشئة
                    </h2>
                    <p className="about-one__text-2 Noto-Regular">ملتزمون بتقديم التميز من خلال
                        الإبداع والتعاون والتكنولوجيا الحديثة. من خلال التركيز على النزاهة والاحترافية ورضا العملاء</p>
                </div>

            </div>
            <div className='contain-div'>
                <div className="container-2 container">
                    <div className="row">
                        <div className="col-md-6 one-div">
                            <div
                                className='div-info'>


                                <div className="section-3" >
                                    <button className="thm-btn Noto-Regular goals-lg thm-btn-1"
                                    >
                                        هدفنا</button>
                                    <h2 className="Noto-Regular goals  lg-p"
                                    >
                                        تمكين الشركات - الكبيرة والناشئة - والأفراد بحلول رقمية مبتكرة تدفع نحو النمو،
                                        والتحول الرقمي السلس، وتلهم النجاح.
                                    </h2>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6 two-div"
                        >
                            {/* <div className="vision-xl">
                                <p className="Noto-Regular">
                                    رؤيتنا</p>
                            </div> */}
                            {/* <button className="thm-btn Noto-Regular thm-btn-2"
                            >
                                رؤيتنا</button> */}
                            <div className="content content-1" >
                                <h3 className="Noto-Regular" > <span className="SometypeMono"
                                > 01</span> &nbsp;&nbsp;&nbsp; تميز </h3>
                                <p className="Noto-Regular" >ملتزمون بتقديم
                                    التميز من خلال الإبداع والتعاون والتكنولوجيا الحديثة. من خلال التركيز على النزاهة
                                    والاحترافية ورضا العملاء</p>
                            </div>
                            <div className="content content-2">
                                <h3 className="Noto-Regular"> <span className="SometypeMono"
                                > 02</span> &nbsp;&nbsp;&nbsp; ثقة </h3>
                                <p className="Noto-Regular" > نسعى لأن نكون
                                    شريكاً موثوقًا في رحلة عملائنا نحو التحول الرقمي </p>
                            </div>
                            <div className="content">
                                <h3 className="Noto-Regular"> <span className="SometypeMono"
                                > 03</span> &nbsp;&nbsp;&nbsp; حلول مخصصة
                                </h3>
                                <p className="Noto-Regular" >هدفنا ليس فقط لتلبية
                                    التوقعات، ولكن لتجاوزها، من خلال تقديم حلول مخصصة عالية الجودة تحقق تأثيرًا معنويًا
                                    في المشهد الرقمي</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurPropose